require('intersection-observer');

const options = {
    root: null,
    rootMargin: "0px 0px -96% 0px",
    threshold: 0 // 閾値は0
};

const observer = new IntersectionObserver(doWhenIntersect, options);

const boxes = document.querySelectorAll("#contents");
//IE11対策
const boxesArr = Array.prototype.slice.call(boxes);
boxesArr.forEach(box => {
    observer.observe(box);
});

function doWhenIntersect(entries) {
    //IE11対策
    const entriesArr = Array.prototype.slice.call(entries);
    entriesArr.forEach(entry => {
        if (entry.isIntersecting) {
            document.getElementById('drawer_open').classList.add("inview");
            
            const src = document.querySelector('#drawer_open img').getAttribute('src');
            const srcreplace = src.replace('_w', '_bk');
            document.querySelector('#drawer_open img').setAttribute('src', srcreplace);
            
        } else {
            document.getElementById('drawer_open').classList.remove("inview");
            
            const src = document.querySelector('#drawer_open img').getAttribute('src');
            const srcreplace = src.replace('_bk', '_w');
            document.querySelector('#drawer_open img').setAttribute('src', srcreplace);
            
        }
    });
}
